import getConfig from '@/config';
import { Maybe } from '@/generated/graphql';
import { slugify } from '@/lib/utils';

const makeObjectId = ({ id, name }: { id: number; name: string }) =>
  `${slugify(name)}-${id}`;

const backOfficeMap = (namespace: string) => ({
  root: `/${namespace}`,
  clients: {
    index: `/${namespace}/clients`,
    create: `/${namespace}/clients/create`,
    get: ({ id }: { id: number }) => `/${namespace}/clients/${id}`,
  },
  listings: {
    index: `/${namespace}/listings`,
    get: ({ id, suffix = '' }: { id: number; suffix?: string }) =>
      `/${namespace}/listings/${id}${suffix}`,
    basicInformation: {
      get: ({
        listingId,
        propertyId = '',
        suffix = '',
      }: {
        listingId: number;
        propertyId?: number | string;
        suffix?: string;
      }) =>
        `/${namespace}/listings/${listingId}/basic-information/${propertyId}${suffix}`.replace(
          /\/$/,
          '',
        ),
    },
    salesBrochure: {
      get: ({
        listingId,
        propertyId = '',
        suffix = '',
      }: {
        listingId: number;
        propertyId?: number | string;
        suffix?: string;
      }) =>
        `/${namespace}/listings/${listingId}/sales-brochure/${propertyId}${suffix}`.replace(
          /\/$/,
          '',
        ),
    },
    publish: {
      get: ({ listingId }: { listingId: number }) =>
        `/${namespace}/listings/${listingId}/publish`,
    },
    propertyImages: {
      get: ({
        listingId,
        propertyId,
        suffix = '',
      }: {
        listingId: number;
        propertyId: number;
        suffix?: string;
      }) =>
        `/${namespace}/listings/${listingId}/property-images/${propertyId}${suffix}`,
    },
    questionsAndOffers: {
      get: ({ listingId }: { listingId: number }) =>
        `/${namespace}/listings/${listingId}/questions-and-offers`,
    },
    preview: {
      get: ({
        listingPublicId,
        locale = 'fi',
      }: {
        listingPublicId?: string | null;
        locale?: string;
      }) =>
        listingPublicId
          ? `/api/preview?publicId=${listingPublicId}&locale=${locale}`
          : '',
    },
  },
  diary: {
    index: `/${namespace}/diary`,
  },
  reports: {
    index: `/${namespace}/reports`,
  },
  valueWizard: {
    index: `/${namespace}/valuewizard`,
  },
  agencies: {
    index: `/${namespace}/agencies`,
    create: `/${namespace}/agencies/create`,
    get: ({ id }: { id: number }) => `/${namespace}/agencies/${id}`,
    locations: {
      index: ({ agencyId }: { agencyId: number }) =>
        `/${namespace}/agencies/${agencyId}/locations`,
    },
    users: {
      index: ({ agencyId }: { agencyId: number }) =>
        `/${namespace}/agencies/${agencyId}/users`,
      get: ({ agencyId, userId }: { agencyId: number; userId: number }) =>
        `/${namespace}/agencies/${agencyId}/users/${userId}`,
    },
    settings: {
      index: ({ agencyId }: { agencyId: number }) =>
        `/${namespace}/agencies/${agencyId}/settings`,
    },
  },
  profile: {
    get: `/${namespace}/profile`,
  },
});

const publicMap = {
  root: '/',
  buy: {
    index: '/osta',
  },
  listing: {
    get: (publicId: string) => `/osta/${publicId}`,
    offer: (publicId: string) => `/osta/${publicId}/tarjous`,
    brochure: (publicId: string, locale = 'fi') =>
      `/api/listing/${publicId}/brochure.pdf?locale=${locale}`,
  },
  sell: {
    index: '/myy',
  },
  instructions: {
    index: '/ohjeet',
  },
  contacts: {
    index: '/yhteystiedot',
  },
  information: {
    index: '/tietoa-palvelusta',
  },
  agencies: {
    slug: (obj: { id: number; name: string }) => makeObjectId(obj),
    get: (obj: { id: number; name: string }) =>
      `/valitysliike/${makeObjectId(obj)}`,
  },
  realtors: {
    slug: (obj: { id: number; name: string }) => makeObjectId(obj),
    get: (obj: { id: number; name: string }) =>
      `/valittaja/${makeObjectId(obj)}`,
  },
  tilavahti: {
    index: '/tilavahti',
    get: (accessToken: string) => `/tilavahti/${accessToken}`,
    verify: (accessToken: string) => `/tilavahti/${accessToken}/verify`,
  },
};

export const urls = {
  backOffice: backOfficeMap('back-office'),
  public: publicMap,
};

export const localeURL = (locale: string, path: string) => {
  const { LOCALE_BASE_URL } = getConfig({
    locale,
  });
  return `${LOCALE_BASE_URL}${path}`;
};

export const attachmentUrl = (
  attachment:
    | Maybe<{ container: string; file_path: string; original_filename: string }>
    | undefined,
  config: { withHostname?: boolean; locale?: string; apiRoute?: string } = {
    withHostname: false,
  },
): string | undefined => {
  if (!attachment) {
    return undefined;
  }

  return [
    config.withHostname
      ? getConfig({ locale: config.locale }).PUBLIC_HOST_URL
      : '',
    'api',
    config.apiRoute || 'attachment',
    attachment.container,
    attachment.file_path,
  ].join('/');
};

export const publicContainerAttachmentUrl = (
  attachment: Maybe<{ container: string; file_path: string }> | undefined,
  config: { queryString?: string } = {
    queryString: '',
  },
) => {
  const assetHostname = process.env.NEXT_PUBLIC_ASSETS_CDN_HOSTNAME;

  if (!attachment) {
    return undefined;
  }

  const base = [assetHostname, attachment.container, attachment.file_path].join(
    '/',
  );

  return config.queryString ? `${base}?${config.queryString}` : base;
};

/**
 * Generates a "fake" next/image URL for use where it's not possible to
 * use the Image component from next/image, but we still want to use
 * an optimized image version.
 *
 * The width property must be one of the specified sizes in `next.config.js`.
 */
export const optimizedPublicContainerAttachmentUrl = (
  attachment: Maybe<{ container: string; file_path: string }> | undefined,
  width: number,
  quality: number,
) => {
  const publicAttachmentUrl = publicContainerAttachmentUrl(attachment);
  const defaultAssetPrefix = process.env.NEXT_PUBLIC_ASSET_PREFIX;
  // in development we don't use the asset prefix, instead
  // use our normal URL. This is actually only needed when using
  // the URLs server side, but doesn't hurt to include it always
  const localAssetPrefix = process.env.NEXT_PUBLIC_HOST_URL;

  if (!publicAttachmentUrl) {
    return undefined;
  }

  return `${
    defaultAssetPrefix || localAssetPrefix || ''
  }/_next/image?url=${publicAttachmentUrl}&w=${width}&q=${quality}`;
};
